import styles from './LandingPage.module.css'
import logo from '../../img/logo.png'
import { IoLogoInstagram } from 'react-icons/io5'
import { IoLogoTiktok } from 'react-icons/io5'
import { IoLogoFacebook } from 'react-icons/io'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import { IoLocationOutline } from 'react-icons/io5'
import { LuClock8 } from 'react-icons/lu'
import { MdOutlinePhoneAndroid } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import engineBg from '../../img/engine-bg.svg'
import LogoCarousel from '../../components/carousel/LogoCarousel'
import { useState } from 'react'
import sprintUzh from '../../img/shop/sprint-uzh.jpg'
import sprintUzh2 from '../../img/shop/sprint-uzh2.jpg'
import sprintUzh3 from '../../img/shop/sprint-uzh3.jpg'
import sprintChop from '../../img/shop/sprint-chop.jpg'
import sprintChop2 from '../../img/shop/sprint-chop2.jpg'

const LandingPage = () => {
  const [activeCity, setActiveCity] = useState('Ужгород')

  const [activePhotoIndex, setActivePhotoIndex] = useState(0)

  const photos = {
    Ужгород: [sprintUzh, sprintUzh2, sprintUzh3],
    Чоп: [sprintChop, sprintChop2],
  }

  const handleNextPhoto = () => {
    setActivePhotoIndex((prev) => (prev + 1) % photos[activeCity].length)
  }

  const handlePrevPhoto = () => {
    setActivePhotoIndex(
      (prev) =>
        (prev - 1 + photos[activeCity].length) % photos[activeCity].length
    )
  }

  const handlePhotoClick = (index) => {
    setActivePhotoIndex(index)
  }

  const handleCityClick = (city) => {
    setActivePhotoIndex(0)
    setActiveCity(city)
  }

  const navigate = useNavigate()
  return (
    <div className="page">
      <div className={styles.pageContainer}>
        <div className={styles.firstBlockContainer}>
          <div className={styles.navContainer}>
            <div className={styles.logoContainer}>
              <img src={logo} alt="logo" className={styles.logo} />
            </div>
            <div className={styles.iconsContainer}>
              <a href="https://www.instagram.com/sprint_ua" target="blank">
                <IoLogoInstagram className={styles.socIcon} />
              </a>
              <a
                href="https://www.tiktok.com/@sprint_ua3?is_from_webapp=1&sender_device=pc"
                target="blank"
              >
                <IoLogoTiktok className={styles.socIcon} />
              </a>
              <a href="https://www.facebook.com/sprintllc" target="blank">
                <IoLogoFacebook className={styles.socIcon} />
              </a>
            </div>
            <div className={styles.loginContainer}>
              <button
                className={styles.signUpBtn}
                onClick={() => navigate('/register')}
              >
                Реєстрація
              </button>
              <button
                className={styles.loginBtn}
                onClick={() => navigate('/login')}
              >
                Вхід
              </button>
            </div>
          </div>
          <div className={styles.firstBlockContentContainer}>
            <div className={styles.contentBlock}>
              <span className={styles.title}>Автомагазин "Спринт"</span>
              <span className={styles.subTitle}>
                Запчастини, аксесуари, інструменти гуртом та в роздріб.
              </span>
              <div className={styles.mobileLoginContainer}>
                <button
                  className={styles.signUpBtn}
                  onClick={() => navigate('/register')}
                >
                  Реєстрація
                </button>
                <button
                  className={styles.loginBtn}
                  onClick={() => navigate('/login')}
                >
                  Вхід
                </button>
              </div>
            </div>
            <div className={styles.imgContentBlock}>
              <img
                src={engineBg}
                alt="engine"
                width="700"
                height="700"
                className={styles.engineBg}
              />
            </div>
          </div>
        </div>
        <div className={styles.carouselContainer}>
          <LogoCarousel />
        </div>
        <div className={styles.secondBlockContainer}>
          <span className={styles.titleContacts}>НАШІ МАГАЗИНИ</span>
          <div className={styles.cityPickerContainer}>
            <div className={styles.cityContainer}>
              <div
                className={`${styles.city} ${
                  activeCity === 'Ужгород' ? styles.activeCity : ''
                }`}
                onClick={() => handleCityClick('Ужгород')}
              >
                <span className={styles.cityTitle}>Ужгород</span>
                <span className={styles.cityStreetTitle}>
                  вул. Мукачівська 58
                </span>
              </div>
              <div
                className={`${styles.city} ${
                  activeCity === 'Чоп' ? styles.activeCity : ''
                }`}
                onClick={() => handleCityClick('Чоп')}
              >
                <span className={styles.cityTitle}>Чоп</span>
                <span className={styles.cityStreetTitle}>
                  вул. Залізнична 2
                </span>
              </div>
            </div>
            <div className={styles.photo}>
              <div className={styles.photoContainer}>
                <img
                  src={photos[activeCity][activePhotoIndex]}
                  alt="Магазин"
                  className={styles.mainPhoto}
                />
              </div>
              <div className={styles.controlsContainer}>
                <button
                  className={styles.arrowButton}
                  onClick={handlePrevPhoto}
                >
                  <IoIosArrowBack />
                </button>
                <div className={styles.thumbnailContainer}>
                  {photos[activeCity].map((photo, index) => (
                    <img
                      key={index}
                      src={photo}
                      alt={`Магазин ${index + 1}`}
                      className={`${styles.thumbnail} ${
                        index === activePhotoIndex ? styles.activeThumbnail : ''
                      }`}
                      onClick={() => handlePhotoClick(index)}
                    />
                  ))}
                </div>
                <button
                  className={styles.arrowButton}
                  onClick={handleNextPhoto}
                >
                  <IoIosArrowForward />
                </button>
              </div>
            </div>

            <div className={styles.data}>
              {activeCity === 'Ужгород' && (
                <div className={styles.shopInfoContainer}>
                  <div className={styles.shopInfo}>
                    <div className={styles.iconContainer}>
                      <IoLocationOutline />
                    </div>
                    <div className={styles.infoContainer}>
                      <span className={styles.titleInfo}>Ужгород</span>
                      <span className={styles.secondaryTitleInfo}>
                        вул. Мукачівська 58
                      </span>
                    </div>
                  </div>
                  <div className={styles.shopInfo}>
                    <div className={styles.iconContainer}>
                      <LuClock8 />
                    </div>
                    <div className={styles.infoContainer}>
                      <span className={styles.titleInfo}>Розклад роботи:</span>
                      <span className={styles.secondaryTitleInfo}>
                        Пн - Пт: 8:30-19:00
                      </span>
                      <span>Сб: 9:00-16:00</span>
                    </div>
                  </div>
                  <div className={styles.shopInfo}>
                    <div className={styles.iconContainer}>
                      <MdOutlinePhoneAndroid />
                    </div>
                    <div className={styles.infoContainer}>
                      <span className={styles.titleInfo}>Телефони:</span>
                      <span className={styles.secondaryTitleInfo}>
                        0 800 33 00 49
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {activeCity === 'Чоп' && (
                <div className={styles.shopInfoContainer}>
                  <div className={styles.shopInfo}>
                    <div className={styles.iconContainer}>
                      <IoLocationOutline />
                    </div>
                    <div className={styles.infoContainer}>
                      <span className={styles.titleInfo}>Чоп</span>
                      <span className={styles.secondaryTitleInfo}>
                        вул. Залізнична 2
                      </span>
                    </div>
                  </div>
                  <div className={styles.shopInfo}>
                    <div className={styles.iconContainer}>
                      <LuClock8 />
                    </div>
                    <div className={styles.infoContainer}>
                      <span className={styles.titleInfo}>Розклад роботи:</span>
                      <span className={styles.secondaryTitleInfo}>
                        Пн - Пт: 9:15-18:00
                      </span>
                      <span>Сб: 9:15-14:00</span>
                    </div>
                  </div>
                  <div className={styles.shopInfo}>
                    <div className={styles.iconContainer}>
                      <MdOutlinePhoneAndroid />
                    </div>
                    <div className={styles.infoContainer}>
                      <span className={styles.titleInfo}>Телефони:</span>
                      <span className={styles.secondaryTitleInfo}>
                        +380 63 63 558
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
