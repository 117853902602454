import React, { useState } from 'react'
import Input from 'react-phone-number-input/input'
import styles from './LoginPage.module.css'
import { Link, useNavigate } from 'react-router-dom'
import Viber from '../../components/viber/Viber'
import { useLoginMutation } from '../../redux/auth/apiAuth'
import { toast } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import { useDispatch } from 'react-redux'
import { setAccessToken } from '../../redux/auth/authSlice'
import { BsFillEyeSlashFill } from 'react-icons/bs'
import { FaEye } from 'react-icons/fa'

const LoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const [login, { isLoadingLogin }] = useLoginMutation()

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleSubmitLoginForm = async (e) => {
    e.preventDefault()
    const validPhoneNumber = username.split('+')[1]
    try {
      const result = await login({
        phoneNumber: validPhoneNumber,
        password,
      }).unwrap()
      if (result.accessToken) {
        dispatch(setAccessToken(result.accessToken))
        navigate('/shop')
      }
    } catch (error) {
      if (
        error.status === 400 &&
        error.data.message === 'Invalid credentials'
      ) {
        toast.error('Невірний логін або пароль')
        setPassword('')
        return
      } else {
        toast.error('Сталась помилка...')
      }
    }
  }

  return (
    <div className="page">
      {isLoadingLogin && <Loader />}
      <div className={styles.loginPageContainer}>
        <div>
          <h2 className={styles.companyName}>СПРИНТ</h2>
        </div>
        <form
          className={styles.loginFormContainer}
          onSubmit={handleSubmitLoginForm}
        >
          <div className={styles.loginTextContainer}>
            <h2>Вхід</h2>
            <p className={styles.secondaryText}>
              Будь ласка, внесіть свої дані в поля форми.
            </p>
          </div>
          <div className={styles.loginForm}>
            <div className={styles.overlayText}>
              <h3>Форма входу</h3>
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelForm}>
                Логін (номер телефону):
              </label>
              <Input
                country="UA"
                international
                withCountryCallingCode
                value={username}
                onChange={setUsername}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.labelForm}>Пароль:</label>
              {isPasswordVisible ? (
                <BsFillEyeSlashFill
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  className={styles.eyeIcon}
                />
              ) : (
                <FaEye
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  className={styles.eyeIcon}
                />
              )}
              <input
                maxLength={50}
                required
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div className={styles.registreTextCont}>
              <Link className={styles.registreText} to="/register">
                Нема облікового запису?
              </Link>
            </div>
            <div className={styles.registreTextCont}>
              <Link className={styles.registreText} to="/reset/password">
                Забули пароль?
              </Link>
            </div>
            <div className={styles.btnContainer}>
              <button className={styles.loginBtn} type="submit">
                Увійти
              </button>
            </div>
          </div>
          <Viber />
        </form>
      </div>
    </div>
  )
}

export default LoginPage
