import { FaArrowAltCircleRight } from 'react-icons/fa'
import styles from './Search.module.css'
import {
  useGetSparesByDescriptionMutation,
  useSearchSparesBrandMutation,
} from '../../../redux/searchSpares/apiSearchSpares'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import Loader from '../../loader/Loader'
import SearchBrand from './searchBrand/SearchBrand'
import filterString from '../../../utils/filterString'
import { useDispatch } from 'react-redux'
import { setSearchedSpare } from '../../../redux/searchSpares/sparesSlice'
import { useGetClientSearchHistoryQuery } from '../../../redux/clientData/apiClientData'

const Search = () => {
  const [searchInput, setSearchInput] = useState('')
  const [searchBrand, setSearchBrand] = useState(null)
  const [isInputHovered, setIsInputHovered] = useState(false)
  const [isSearchByDesc, setIsSearchByDesc] = useState(false)

  const [searchSparesBrand, { isLoading: isLoadingSearchSparesBrand }] =
    useSearchSparesBrandMutation()

  const [
    getSparesByDescription,
    { isLoading: isLoadingSearchSparesByDescription },
  ] = useGetSparesByDescriptionMutation()

  const {
    data: searchHistory,
    isLoading: isLoadingSearchHistory,
    refetch,
  } = useGetClientSearchHistoryQuery()

  useEffect(() => {
    if (isInputHovered) {
      refetch()
    }
  }, [refetch, isInputHovered])

  const dispatch = useDispatch()

  const handleSearchSparesByBrand = async () => {
    setSearchBrand(null)
    try {
      if (isSearchByDesc) {
        handleSearchByDesc()
        return
      }
      const code = filterString(searchInput)
      dispatch(setSearchedSpare(code))
      const response = await searchSparesBrand({ code })
      if (response.data) {
        setSearchBrand(response.data)
      }
    } catch (error) {
      toast.error('Сталась помилка...')
    }
  }

  const handleSearchByDesc = async () => {
    try {
      const spares = await getSparesByDescription({
        desc: searchInput,
      }).unwrap()
      setSearchBrand(spares.spares)
    } catch (error) {
      toast.error('Сталась помилка...')
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSparesByBrand()
    }
  }

  const removeDuplicates = (searchHistory) => {
    const uniqueSet = new Set()

    const uniqueArray = searchHistory.filter((item) => {
      const key = `${item?.brand?.trim()}${item?.code?.trim()}`

      if (uniqueSet.has(key)) {
        return false
      }

      uniqueSet.add(key)

      return true
    })
    return uniqueArray
  }

  return (
    <div className={styles.searchMain}>
      {isLoadingSearchSparesBrand && <Loader />}
      {isLoadingSearchHistory && <Loader />}
      {isLoadingSearchSparesByDescription && <Loader />}
      <div className={styles.searchContainer}>
        <div className={styles.searchTop}>
          <div className={styles.searchText}>Пошук:</div>
          <div
            onMouseEnter={() => setIsInputHovered(true)}
            onMouseLeave={() => setIsInputHovered(false)}
            className={styles.searchInputContainer}
          >
            <input
              className={styles.searchInput}
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              onKeyDown={handleKeyDown}
            />
            {isInputHovered &&
              searchHistory?.clientSearchHistory?.length > 0 && (
                <div className={styles.searchHistoryContainer}>
                  {removeDuplicates(searchHistory?.clientSearchHistory)?.map(
                    (search, i) =>
                      i < 5 &&
                      search.brand &&
                      search.code && (
                        <div
                          key={i}
                          onClick={() => setSearchInput(search.code)}
                          className={styles.searchHistoryItem}
                        >
                          <span>{search.brand}</span>
                          <span>{search.code}</span>
                        </div>
                      )
                  )}
                </div>
              )}
          </div>
          <div className={styles.searchBtnContainer}>
            <button
              onClick={handleSearchSparesByBrand}
              className={styles.searchBtn}
            >
              <FaArrowAltCircleRight />
            </button>
          </div>
          <>
            <span className={styles.descriptionSearchContainer}>
              Пошук по опису
            </span>
            <input
              type="checkbox"
              className={styles.checkbox}
              checked={isSearchByDesc}
              onChange={() => setIsSearchByDesc(!isSearchByDesc)}
            />
          </>
          <span className={styles.warningText}>
            Перевіряйте правильність аналогів та характиристики товарів за
            надійними джерелами!
          </span>
        </div>
        {searchBrand && (
          <SearchBrand
            setSearchBrand={setSearchBrand}
            searchBrand={searchBrand}
          />
        )}
      </div>
    </div>
  )
}

export default Search
