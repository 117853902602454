import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import eligran from '../../img/logos/eligran.svg'
import rpm8t from '../../img/logos/rpm8t.svg'
import et1eco from '../../img/logos/et1eco.svg'
import michelin from '../../img/logos/michelin.svg'
import spidan from '../../img/logos/spidan.svg'
import styles from './LogoCarousel.module.css'

const LogoCarousel = () => {
  const [slidesToShow, setSlidesToShow] = useState(
    window.innerWidth > 1600 ? 3 : window.innerWidth >= 820 ? 2 : 1
  )

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow =
        window.innerWidth > 1600 ? 3 : window.innerWidth >= 820 ? 2 : 1
      setSlidesToShow(newSlidesToShow)
    }

    const resizeTimer = setTimeout(handleResize, 300)
    window.addEventListener('resize', handleResize)

    return () => {
      clearTimeout(resizeTimer)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: 'ease',
    swipe: false,
  }

  const logos = [eligran, rpm8t, et1eco, michelin, spidan]

  return (
    <div className={styles.carouselContainer}>
      <Slider {...settings}>
        {logos.map((v, index) => (
          <div key={index} className={styles.imgContainer}>
            <img src={v} alt="Logo" className={styles.logo} />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default LogoCarousel
