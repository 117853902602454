import { useEffect, useState } from 'react'
import {
  useGetClientDataQuery,
  useGetClientPaymentPlanQuery,
} from '../../../redux/clientData/apiClientData'
import Loader from '../../loader/Loader'
import styles from './PaymentPlan.module.css'

const PaymentPlan = ({ rerender }) => {
  const [isShowAllDaysChecked, setIsShowAllDaysChecked] = useState(false)

  const {
    data: paymentPlan,
    isLoading: isLoadingPaymentPlan,
    refetch,
  } = useGetClientPaymentPlanQuery()

  const { data: clientData, isLoading: isLoadingClientData } =
    useGetClientDataQuery()

  useEffect(() => {
    refetch()
  }, [refetch, rerender])

  const filterZeroSumPayments = (paymentPlan) => {
    const updatedPaymentPlan = [
      {
        ...paymentPlan[0],
        sum: (
          paymentPlan[0].sum +
          countCurrentPayment(clientData?.balance, paymentPlan)
        ).toFixed(2),
      },
      ...paymentPlan.slice(1),
    ]

    return updatedPaymentPlan.filter((payment) => payment.sum > 0)
  }

  const countPaymentTotalSum = (paymentPlan) => {
    return paymentPlan.reduce((acc, payment) => {
      return acc + payment.sum
    }, 0)
  }

  const countCurrentPayment = (balance, paymentPlan) => {
    return Math.abs(balance) - Math.abs(countPaymentTotalSum(paymentPlan))
  }

  if (isLoadingPaymentPlan || isLoadingClientData) return <Loader />
  return (
    <div className={styles.paymentPlanPageContainer}>
      {countPaymentTotalSum(paymentPlan?.clientPaymentPlan) > 0 && (
        <div className={styles.hideZeroSumDaysContainer}>
          <input
            value={isShowAllDaysChecked}
            onChange={() => setIsShowAllDaysChecked(!isShowAllDaysChecked)}
            type="checkbox"
          />
          <span>Сховати дні з 0 сумою до сплати</span>
        </div>
      )}
      <div className={styles.paymentPlanContainer}>
        {countPaymentTotalSum(paymentPlan?.clientPaymentPlan) > 0 ? (
          <table className={styles.paymentPlanTable}>
            <thead>
              <tr>
                <th>Дата</th>
                <th>Сума до сплати</th>
              </tr>
            </thead>
            <tbody>
              {isShowAllDaysChecked
                ? filterZeroSumPayments(paymentPlan?.clientPaymentPlan)?.map(
                    (payment, i) => (
                      <tr key={i}>
                        <td>{payment.date}</td>
                        <td>{payment.sum}</td>
                      </tr>
                    )
                  )
                : paymentPlan?.clientPaymentPlan?.map((payment, i) => (
                    <tr key={i}>
                      <td>{payment.date}</td>
                      <td>
                        {i === 0
                          ? (
                              payment.sum +
                              countCurrentPayment(
                                clientData.balance,
                                paymentPlan?.clientPaymentPlan
                              )
                            ).toFixed(2)
                          : payment.sum}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        ) : (
          <h3 className={styles.noPaymentPlanText}>
            На найближчий час план оплат відсутній
          </h3>
        )}
      </div>
    </div>
  )
}

export default PaymentPlan
