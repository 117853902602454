import { useSelector } from 'react-redux'
import { MdCopyAll } from 'react-icons/md'
import { RiRepeatLine } from 'react-icons/ri'
import { MdPhotoCamera } from 'react-icons/md'
import filterString from '../../../../utils/filterString'
import styles from './SearchFinal.module.css'
import {
  selectHideIncomePrices,
  selectSearchedSpare,
  selectShowReferencePrice,
} from '../../../../redux/searchSpares/sparesSlice'
import { useEffect, useState } from 'react'
import handleSortBy from '../../../../utils/handleSortBy'
import Loader from '../../../loader/Loader'
import { toast } from 'react-toastify'
import ModalDeliveryTimeDetails from '../../../modals/deliveryTimeDetails/ModalDeliveryTimeDetails'
import ModalOrderSpare from '../../../modals/orderSpare/ModalOrderSpare'
import { useGetOrderPermissionMutation } from '../../../../redux/clientData/apiClientData'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useGetCurrencyRatesQuery } from '../../../../redux/data/apiData'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ModalImage from '../../../modals/image/ModalImage'

const SearchFinal = ({
  searchFinal,
  setSearchFinal,
  handleSearchFinal,
  searchedBrand,
}) => {
  const [copied, setCopied] = useState(false)

  const [isSortedByBrand, setIsSortedByBrand] = useState(false)
  const [isSortedByCode, setIsSortedByCode] = useState(false)
  const [isSortedByDescription, setIsSortedByDescription] = useState(false)
  const [isSortedByRetailPrice, setIsSortedByRetailPrice] = useState(false)
  const [isSortedByPrice, setIsSortedByPrice] = useState(false)
  const [isSortedByAvailability, setIsSortedByAvailability] = useState(false)
  const [isSortedByDeliveryTime, setIsSortedByDeliveryTime] = useState(false)

  const [isNoReturnIconHovered, setIsNoReturnIconHovered] = useState(false)

  const [deliveryTimeDetails, setDeliveryTimeDetails] = useState(null)

  const [spareToOrder, setSpareToOrder] = useState([])
  const [isSpareToOrderInternal, setIsSpareToOrderInternal] = useState(null)

  const [imageToOpen, setImageToOpen] = useState(null)

  const searchInput = useSelector(selectSearchedSpare)
  const hideIncomePrices = useSelector(selectHideIncomePrices)
  const showReferencePrice = useSelector(selectShowReferencePrice)

  const [getOrderPermission, { isLoading: isLoadingOrderPermission }] =
    useGetOrderPermissionMutation()
  const { data: currencyRates, isLoading: isLoadingCurrencyRates } =
    useGetCurrencyRatesQuery()

  useEffect(() => {
    handleSortBy(
      searchFinal,
      'price',
      isSortedByPrice,
      setIsSortedByPrice,
      setSearchFinal
    )
  }, [])

  const compareInput = (input, code, availability) => {
    const filteredInput = filterString(input)
    const filteredCode = filterString(code)
    if (filteredInput === filteredCode) {
      return availability > 0
    } else {
      return false
    }
  }

  const checkAvailability = (availability, reserve) => {
    return availability > 0 || reserve > 0
  }

  const checkReserve = (availability, reserve) => {
    return availability === 0 && reserve > 0
  }

  const checkSelectedSupplierBrand = (
    availability,
    reserve,
    code,
    supplierAvailability,
    brand
  ) => {
    return (
      !(availability > 0) &&
      !(reserve > 0) &&
      parseInt(supplierAvailability, 10) !== 0 &&
      filterString(code) === searchInput &&
      filterString(brand) === filterString(searchedBrand)
    )
  }

  const checkSupplierBrand = (
    availability,
    reserve,
    code,
    supplierAvailability,
    brand
  ) => {
    return (
      !(availability > 0) &&
      !(reserve > 0) &&
      parseInt(supplierAvailability, 10) !== 0 &&
      !(
        filterString(code) === searchInput &&
        filterString(brand) === filterString(searchedBrand)
      )
    )
  }

  const handleOrderSpare = async (
    brand,
    code,
    availability,
    price,
    spareID,
    isInternal,
    supplierAvailability = null,
    isReturnable = true
  ) => {
    const orderPermission = await getOrderPermission().unwrap()
    if (orderPermission === false) {
      toast.error('Немає прав на замовлення, зверніться до вашого менеджера...')
      return
    }
    if (brand && code && availability && price && isInternal) {
      const spare = { brand, code, availability, price, spareID, isReturnable }
      setIsSpareToOrderInternal(isInternal)
      setSpareToOrder([spare])
    } else if (brand && code && supplierAvailability && price && !isInternal) {
      const spare = {
        brand,
        code,
        availability: 1000,
        price,
        spareID,
        supplierAvailability: null,
        isReturnable,
      }
      setIsSpareToOrderInternal(isInternal)
      setSpareToOrder([spare])
    } else {
      toast.error('Неможливо замовити запчастину...')
    }
  }

  const onCopy = (text, result) => {
    setCopied(result)
    if (result) {
      toast.success('Артикул скопійовано')
    } else {
      toast.error('Не вдалося скопіювати артикул')
    }
  }

  const specialBrands = ['michelin', 'spidan', 'et1eco', 'eligran']
  const optimalBrands = ['rpm8t']

  const hasAvailableParts = searchFinal.some((el) =>
    checkAvailability(el.availability, el.reserve)
  )
  const hasSelectedSupplierParts = searchFinal.some((el) =>
    checkSelectedSupplierBrand(
      el.availability,
      el.reserve,
      el.code,
      el.supplierAvailability,
      el.brand
    )
  )
  const hasSupplierParts = searchFinal.some((el) =>
    checkSupplierBrand(
      el.availability,
      el.reserve,
      el.code,
      el.supplierAvailability,
      el.brand
    )
  )

  return (
    <>
      {deliveryTimeDetails &&
        deliveryTimeDetails?.supplierOrderInformation &&
        deliveryTimeDetails?.supplierDeliveryInformation && (
          <ModalDeliveryTimeDetails
            setDeliveryTimeDetails={setDeliveryTimeDetails}
            deliveryTimeDetails={deliveryTimeDetails}
          />
        )}
      {spareToOrder.length > 0 && (
        <ModalOrderSpare
          setSpareToOrder={setSpareToOrder}
          spareToOrder={spareToOrder}
          handleSearchFinal={handleSearchFinal}
          isSpareToOrderInternal={isSpareToOrderInternal}
        />
      )}
      {imageToOpen && (
        <ModalImage imageToOpen={imageToOpen} setImageToOpen={setImageToOpen} />
      )}
      {isLoadingOrderPermission && <Loader />}
      {isLoadingCurrencyRates && <Loader />}

      {hasAvailableParts && (
        <>
          <h4>Наявність на складі</h4>
          <div className={styles.tableWrapper}>
            <table className={styles.searchTable}>
              <thead className={styles.theadAvailability}>
                <tr>
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'brand',
                        isSortedByBrand,
                        setIsSortedByBrand,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderBrand}
                  >
                    Бренд
                  </th>
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'code',
                        isSortedByCode,
                        setIsSortedByCode,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderCode}
                  >
                    Номер
                  </th>
                  <th className={styles.tableHeaderInfo}>Фото</th>
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'description',
                        isSortedByDescription,
                        setIsSortedByDescription,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderDesc}
                  >
                    Опис
                  </th>
                  {hideIncomePrices ? null : (
                    <th
                      onClick={() =>
                        handleSortBy(
                          searchFinal,
                          'retailPrice',
                          isSortedByRetailPrice,
                          setIsSortedByRetailPrice,
                          setSearchFinal
                        )
                      }
                      className={styles.tableHeaderPrice}
                    >
                      Роздріб
                    </th>
                  )}
                  {hideIncomePrices ? null : showReferencePrice ? (
                    <th
                      onClick={() =>
                        handleSortBy(
                          searchFinal,
                          'retailPrice',
                          isSortedByPrice,
                          setIsSortedByPrice,
                          setSearchFinal
                        )
                      }
                      className={styles.tableHeaderPrice}
                    >
                      Роздріб
                    </th>
                  ) : null}
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'price',
                        isSortedByPrice,
                        setIsSortedByPrice,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderPrice}
                  >
                    Ціна
                  </th>
                  {showReferencePrice ? (
                    <th
                      onClick={() =>
                        handleSortBy(
                          searchFinal,
                          'price',
                          isSortedByPrice,
                          setIsSortedByPrice,
                          setSearchFinal
                        )
                      }
                      className={styles.tableHeaderPrice}
                    >
                      Ціна
                    </th>
                  ) : null}
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'availability',
                        isSortedByAvailability,
                        setIsSortedByAvailability,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderAvailability}
                  >
                    Наявність
                  </th>
                  <th className={styles.tableHeaderReserve}>Резерв</th>
                  <th className={styles.tableHeaderSupplierAvailability}>
                    Заказ
                  </th>
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'deliveryTime',
                        isSortedByDeliveryTime,
                        setIsSortedByDeliveryTime,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderDelivery}
                  >
                    Термін
                  </th>
                  <th className={styles.tableHeaderUpdated}>Оновлено</th>
                </tr>
              </thead>
              <tbody>
                {searchFinal.map(
                  (el, i) =>
                    checkAvailability(el.availability, el.reserve) && (
                      <tr
                        onClick={() =>
                          handleOrderSpare(
                            el.brand,
                            el.code,
                            el.availability,
                            el.price,
                            el.spareID,
                            true,
                            el.supplierAvailability
                          )
                        }
                        className={
                          compareInput(searchInput, el.code, el.availability)
                            ? styles.selectedSpareTr
                            : styles.spareTr
                        }
                        key={i}
                        data-tooltip-id={
                          specialBrands.includes(filterString(el.brand))
                            ? 'tooltip'
                            : 'tooltip'
                        }
                        data-tooltip-content={
                          specialBrands.includes(filterString(el.brand))
                            ? `Додатковий заробіток: ${(
                                el.retailPrice - el.price
                              ).toFixed(2)} (${(
                                (el.retailPrice / el.price - 1) *
                                100
                              ).toFixed(2)}%)`
                            : `Заробіток: ${(el.retailPrice - el.price).toFixed(
                                2
                              )} (${(
                                (el.retailPrice / el.price - 1) *
                                100
                              ).toFixed(2)}%)`
                        }
                      >
                        <td
                          className={
                            checkReserve(el.availability, el.reserve)
                              ? styles.tableRowReserved
                              : specialBrands.includes(filterString(el.brand))
                              ? styles.tableRowSpecial
                              : optimalBrands.includes(filterString(el.brand))
                              ? styles.tableRowOptimal
                              : styles.tableRow
                          }
                        >
                          {el.brand}
                        </td>
                        <td
                          className={
                            checkReserve(el.availability, el.reserve)
                              ? styles.tableRowReserved
                              : specialBrands.includes(filterString(el.brand))
                              ? styles.tableRowSpecial
                              : optimalBrands.includes(filterString(el.brand))
                              ? styles.tableRowOptimal
                              : styles.tableRow
                          }
                        >
                          {el.code}
                          <CopyToClipboard text={el.code} onCopy={onCopy}>
                            <span
                              onClick={(e) => e.stopPropagation()}
                              className={styles.copyIconSpan}
                            >
                              <MdCopyAll />
                            </span>
                          </CopyToClipboard>
                        </td>
                        <td
                          className={
                            checkReserve(el.availability, el.reserve)
                              ? styles.tableRowReserved
                              : specialBrands.includes(filterString(el.brand))
                              ? styles.tableRowSpecial
                              : optimalBrands.includes(filterString(el.brand))
                              ? styles.tableRowOptimal
                              : styles.tableRow
                          }
                          onClick={(e) => {
                            e.stopPropagation()
                            setImageToOpen(el.photoUrl)
                          }}
                        >
                          <span className={styles.imageWrapper}>
                            {el.photoUrl && el.photoUrl[0].photo_url ? (
                              <MdPhotoCamera className={styles.imageIcon} />
                            ) : (
                              <span className={styles.imagePlaceholder}></span>
                            )}
                          </span>
                        </td>
                        <td
                          className={
                            checkReserve(el.availability, el.reserve)
                              ? styles.tableRowReserved
                              : specialBrands.includes(filterString(el.brand))
                              ? styles.tableRowSpecial
                              : optimalBrands.includes(filterString(el.brand))
                              ? styles.tableRowOptimal
                              : styles.tableRow
                          }
                        >
                          {el.description}
                        </td>
                        <td
                          className={
                            checkReserve(el.availability, el.reserve)
                              ? styles.tableRowReserved
                              : specialBrands.includes(filterString(el.brand))
                              ? styles.tableRowSpecial
                              : optimalBrands.includes(filterString(el.brand))
                              ? styles.tableRowOptimal
                              : styles.tableRow
                          }
                        >
                          {el.retailPrice}
                        </td>
                        {showReferencePrice ? (
                          <td
                            className={
                              checkReserve(el.availability, el.reserve)
                                ? styles.tableRowReserved
                                : specialBrands.includes(filterString(el.brand))
                                ? styles.tableRowSpecial
                                : optimalBrands.includes(filterString(el.brand))
                                ? styles.tableRowOptimal
                                : styles.tableRow
                            }
                          >
                            {(el.retailPrice * currencyRates?.eur).toFixed(2)}
                          </td>
                        ) : null}
                        {hideIncomePrices ? null : (
                          <td
                            className={
                              checkReserve(el.availability, el.reserve)
                                ? styles.tableRowReserved
                                : specialBrands.includes(filterString(el.brand))
                                ? styles.tableRowSpecial
                                : optimalBrands.includes(filterString(el.brand))
                                ? styles.tableRowOptimal
                                : styles.tableRow
                            }
                          >
                            {el.price}
                          </td>
                        )}
                        {hideIncomePrices ? null : showReferencePrice ? (
                          <td
                            className={
                              checkReserve(el.availability, el.reserve)
                                ? styles.tableRowReserved
                                : specialBrands.includes(filterString(el.brand))
                                ? styles.tableRowSpecial
                                : optimalBrands.includes(filterString(el.brand))
                                ? styles.tableRowOptimal
                                : styles.tableRow
                            }
                          >
                            {(el.price * currencyRates?.eur).toFixed(2)}
                          </td>
                        ) : null}
                        <td
                          className={
                            checkReserve(el.availability, el.reserve)
                              ? styles.tableRowAvailabilityReserved
                              : specialBrands.includes(filterString(el.brand))
                              ? styles.tableRowSpecialAvailability
                              : optimalBrands.includes(filterString(el.brand))
                              ? styles.tableRowOptimalAvailability
                              : styles.tableRowAvailability
                          }
                        >
                          {el.availability > 0 && el.availability > 10
                            ? '10+'
                            : el.availability}
                        </td>
                        <td
                          className={
                            checkReserve(el.availability, el.reserve)
                              ? styles.tableRowAvailabilityReserved
                              : specialBrands.includes(filterString(el.brand))
                              ? styles.tableRowSpecialAvailability
                              : optimalBrands.includes(filterString(el.brand))
                              ? styles.tableRowOptimalAvailability
                              : styles.tableRowAvailability
                          }
                        >
                          {el.reserve > 0 && el.reserve}
                        </td>
                        <td
                          className={
                            checkReserve(el.availability, el.reserve)
                              ? styles.tableRowReserved
                              : specialBrands.includes(filterString(el.brand))
                              ? styles.tableRowSpecial
                              : optimalBrands.includes(filterString(el.brand))
                              ? styles.tableRowOptimal
                              : styles.tableRow
                          }
                        >
                          {el.supplierAvailability > 0 &&
                            el.supplierAvailability}
                        </td>
                        <td
                          className={
                            checkReserve(el.availability, el.reserve)
                              ? styles.tableRowReserved
                              : specialBrands.includes(filterString(el.brand))
                              ? styles.tableRowSpecial
                              : optimalBrands.includes(filterString(el.brand))
                              ? styles.tableRowOptimal
                              : styles.tableRow
                          }
                        >
                          {el.deliveryTime}
                        </td>
                        <td
                          className={
                            checkReserve(el.availability, el.reserve)
                              ? styles.tableRowReserved
                              : specialBrands.includes(filterString(el.brand))
                              ? styles.tableRowSpecial
                              : optimalBrands.includes(filterString(el.brand))
                              ? styles.tableRowOptimal
                              : styles.tableRow
                          }
                        ></td>
                      </tr>
                    )
                )}
                <Tooltip id="tooltip" />
              </tbody>
            </table>
          </div>
        </>
      )}
      {hasSelectedSupplierParts && (
        <>
          <h4
            className={
              hasAvailableParts
                ? styles.supplierText
                : styles.supplierTextVisibleTH
            }
          >
            Запрошений артикул
          </h4>
          <table className={styles.searchTableSupplier}>
            <thead
              className={
                hasAvailableParts
                  ? styles.theadSupplierTable
                  : styles.theadSupplierTableVisible
              }
            >
              <tr>
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'brand',
                      isSortedByBrand,
                      setIsSortedByBrand,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderBrand}
                >
                  Бренд
                </th>
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'code',
                      isSortedByCode,
                      setIsSortedByCode,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderCode}
                >
                  Номер
                </th>
                <th className={styles.tableHeaderInfo}>Фото</th>
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'description',
                      isSortedByDescription,
                      setIsSortedByDescription,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderDesc}
                >
                  Опис
                </th>
                {hideIncomePrices ? null : (
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'retailPrice',
                        isSortedByRetailPrice,
                        setIsSortedByRetailPrice,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderPrice}
                  >
                    Роздріб
                  </th>
                )}
                {hideIncomePrices ? null : showReferencePrice ? (
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'retailPrice',
                        isSortedByPrice,
                        setIsSortedByPrice,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderPrice}
                  >
                    Роздріб
                  </th>
                ) : null}
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'price',
                      isSortedByPrice,
                      setIsSortedByPrice,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderPrice}
                >
                  Ціна
                </th>
                {showReferencePrice ? (
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'price',
                        isSortedByPrice,
                        setIsSortedByPrice,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderPrice}
                  >
                    Ціна
                  </th>
                ) : null}
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'availability',
                      isSortedByAvailability,
                      setIsSortedByAvailability,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderAvailability}
                >
                  Наявність
                </th>
                <th className={styles.tableHeaderReserve}>Резерв</th>
                <th className={styles.tableHeaderSupplierAvailability}>
                  Заказ
                </th>
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'deliveryTime',
                      isSortedByDeliveryTime,
                      setIsSortedByDeliveryTime,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderDelivery}
                >
                  Термін
                </th>
                <th className={styles.tableHeaderUpdated}>Оновлено</th>
              </tr>
            </thead>

            <tbody className={styles.tbodySupplierTableSelected}>
              {searchFinal.map(
                (el, i) =>
                  checkSelectedSupplierBrand(
                    el.availability,
                    el.reserve,
                    el.code,
                    el.supplierAvailability,
                    el.brand
                  ) && (
                    <tr
                      onClick={() =>
                        handleOrderSpare(
                          el.brand,
                          el.code,
                          el.availability,
                          el.price,
                          el.spareID,
                          false,
                          el.supplierAvailability,
                          el.returnable
                        )
                      }
                      key={i}
                    >
                      <td className={styles.tableRowSupplierSelected}>
                        {el.brand}
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {el.code}
                        <CopyToClipboard text={el.code} onCopy={onCopy}>
                          <span
                            onClick={(e) => e.stopPropagation()}
                            className={styles.copyIconSpan}
                          >
                            <MdCopyAll />
                          </span>
                        </CopyToClipboard>
                      </td>
                      <td
                        className={styles.tableRowSupplierSelected}
                        onClick={(e) => {
                          e.stopPropagation()
                          setImageToOpen(el.photoUrl)
                        }}
                      >
                        <span className={styles.imageWrapper}>
                          {el.photoUrl && el.photoUrl[0].photo_url ? (
                            <MdPhotoCamera className={styles.imageIcon} />
                          ) : (
                            <span className={styles.imagePlaceholder}></span>
                          )}
                        </span>
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {el.description}
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {el.retailPrice}
                      </td>
                      {showReferencePrice ? (
                        <td className={styles.tableRowSupplierSelected}>
                          {(el.retailPrice * currencyRates?.eur).toFixed(2)}
                        </td>
                      ) : null}
                      {hideIncomePrices ? null : (
                        <td className={styles.tableRowSupplierSelected}>
                          {el.price}
                        </td>
                      )}
                      {hideIncomePrices ? null : showReferencePrice ? (
                        <td className={styles.tableRowSupplierSelected}>
                          {(el.price * currencyRates?.eur).toFixed(2)}
                        </td>
                      ) : null}
                      <td className={styles.tableRowSupplierSelected}>
                        {el.availability > 0 && el.availability}
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {!el.returnable && (
                          <RiRepeatLine
                            onMouseEnter={() => setIsNoReturnIconHovered(i)}
                            onMouseLeave={() => setIsNoReturnIconHovered(null)}
                            className={styles.noReturnIcon}
                          />
                        )}
                        {isNoReturnIconHovered === i && (
                          <span className={styles.noReturnTextContainer}>
                            Не підлягає поверненню
                          </span>
                        )}
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {el.supplierAvailability}
                      </td>
                      <td
                        className={
                          el.isDeliveryTimeReliable
                            ? styles.deliveryTimeReliable
                            : styles.tableRowSupplierSelected
                        }
                      >
                        <span>{el.deliveryTime} дн.</span>
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {el.updateDate}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </>
      )}
      {hasSupplierParts && (
        <>
          <h4
            className={
              !hasSelectedSupplierParts && !hasAvailableParts
                ? styles.supplierTextVisibleTH
                : styles.supplierText
            }
          >
            Аналоги
          </h4>
          <table className={styles.searchTableSupplier}>
            <thead
              className={
                !hasSelectedSupplierParts && !hasAvailableParts
                  ? styles.theadSupplierTableVisible
                  : styles.theadSupplierTable
              }
            >
              <tr>
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'brand',
                      isSortedByBrand,
                      setIsSortedByBrand,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderBrand}
                >
                  Бренд
                </th>
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'code',
                      isSortedByCode,
                      setIsSortedByCode,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderCode}
                >
                  Номер
                </th>
                <th className={styles.tableHeaderInfo}>Фото</th>
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'description',
                      isSortedByDescription,
                      setIsSortedByDescription,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderDesc}
                >
                  Опис
                </th>
                {hideIncomePrices ? null : (
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'retailPrice',
                        isSortedByRetailPrice,
                        setIsSortedByRetailPrice,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderPrice}
                  >
                    Роздріб
                  </th>
                )}
                {hideIncomePrices ? null : showReferencePrice ? (
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'retailPrice',
                        isSortedByPrice,
                        setIsSortedByPrice,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderPrice}
                  >
                    Роздріб
                  </th>
                ) : null}
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'price',
                      isSortedByPrice,
                      setIsSortedByPrice,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderPrice}
                >
                  Ціна
                </th>
                {showReferencePrice ? (
                  <th
                    onClick={() =>
                      handleSortBy(
                        searchFinal,
                        'price',
                        isSortedByPrice,
                        setIsSortedByPrice,
                        setSearchFinal
                      )
                    }
                    className={styles.tableHeaderPrice}
                  >
                    Ціна
                  </th>
                ) : null}
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'availability',
                      isSortedByAvailability,
                      setIsSortedByAvailability,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderAvailability}
                >
                  Наявність
                </th>
                <th className={styles.tableHeaderReserve}>Резерв</th>
                <th className={styles.tableHeaderSupplierAvailability}>
                  Заказ
                </th>
                <th
                  onClick={() =>
                    handleSortBy(
                      searchFinal,
                      'deliveryTime',
                      isSortedByDeliveryTime,
                      setIsSortedByDeliveryTime,
                      setSearchFinal
                    )
                  }
                  className={styles.tableHeaderDelivery}
                >
                  Термін
                </th>
                <th className={styles.tableHeaderUpdated}>Оновлено</th>
              </tr>
            </thead>

            <tbody>
              {searchFinal.map(
                (el, i) =>
                  checkSupplierBrand(
                    el.availability,
                    el.reserve,
                    el.code,
                    el.supplierAvailability,
                    el.brand
                  ) && (
                    <tr
                      onClick={() =>
                        handleOrderSpare(
                          el.brand,
                          el.code,
                          el.availability,
                          el.price,
                          el.spareID,
                          false,
                          el.supplierAvailability,
                          el.returnable
                        )
                      }
                      key={i}
                    >
                      <td className={styles.tableRowSupplierSelected}>
                        {el.brand}
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {el.code}
                        <CopyToClipboard text={el.code} onCopy={onCopy}>
                          <span
                            onClick={(e) => e.stopPropagation()}
                            className={styles.copyIconSpan}
                          >
                            <MdCopyAll />
                          </span>
                        </CopyToClipboard>
                      </td>
                      <td
                        className={styles.tableRowSupplierSelected}
                        onClick={(e) => {
                          e.stopPropagation()
                          setImageToOpen(el.photoUrl)
                        }}
                      >
                        <span className={styles.imageWrapper}>
                          {el.photoUrl && el.photoUrl[0].photo_url ? (
                            <MdPhotoCamera className={styles.imageIcon} />
                          ) : (
                            <span className={styles.imagePlaceholder}></span>
                          )}
                        </span>
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {el.description}
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {el.retailPrice}
                      </td>
                      {showReferencePrice ? (
                        <td className={styles.tableRowSupplierSelected}>
                          {(el.retailPrice * currencyRates?.eur).toFixed(2)}
                        </td>
                      ) : null}
                      {hideIncomePrices ? null : (
                        <td className={styles.tableRowSupplierSelected}>
                          {el.price}
                        </td>
                      )}
                      {hideIncomePrices ? null : showReferencePrice ? (
                        <td className={styles.tableRowSupplierSelected}>
                          {(el.price * currencyRates?.eur).toFixed(2)}
                        </td>
                      ) : null}
                      <td className={styles.tableRowSupplierSelected}>
                        {el.availability > 0 && el.availability}
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {!el.returnable && (
                          <RiRepeatLine
                            onMouseEnter={() => setIsNoReturnIconHovered(i)}
                            onMouseLeave={() => setIsNoReturnIconHovered(null)}
                            className={styles.noReturnIcon}
                          />
                        )}
                        {isNoReturnIconHovered === i && (
                          <span className={styles.noReturnTextContainer}>
                            Не підлягає поверненню
                          </span>
                        )}
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {el.supplierAvailability}
                      </td>
                      <td
                        className={
                          el.isDeliveryTimeReliable
                            ? styles.deliveryTimeReliable
                            : styles.tableRowSupplierSelected
                        }
                      >
                        <span>{el.deliveryTime} дн.</span>
                      </td>
                      <td className={styles.tableRowSupplierSelected}>
                        {el.updateDate}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </>
      )}
    </>
  )
}

export default SearchFinal
